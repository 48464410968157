<template>
  <section class="text-white">
    <!-- หัวข้อสินค้า -->
    <div class="container">
      <div class="flex justify-between items-center">
        <h2 class="text-sm font-bold">RECOMMENDED</h2>
        <router-link to="/products" class="text-sm font-semibold text-white hover:text-green-400">
          VIEW ALL PRODUCT →
        </router-link>
      </div>
    </div>

    <!-- ปุ่มสินค้า -->
    <div class="container mx-auto py-2">
      <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
        <ProductCard
          v-for="product in products"
          :key="product.name"
          :name="product.name"
          :description="product.description"
          :price="product.price"
          :image="product.imagePath"
        />
      </div>
    </div>
  </section>
</template>

<script>
import ProductCard from "@/components/ProductCard.vue";
import { fetchProducts } from "@/services/productsService";

export default {
  name: "RecommendedProducts",
  components: {
    ProductCard,
  },
  data() {
    return {
      products: [], // เก็บข้อมูลสินค้า
    };
  },
  mounted() {
    this.loadProducts(); // โหลดข้อมูลเมื่อคอมโพเนนต์ถูก mount
  },
  methods: {
    async loadProducts() {
      try {
        const allProducts = await fetchProducts(); // ดึงข้อมูลจาก API
        this.products = allProducts.slice(0, 3); // เลือกเฉพาะ 3 รายการแรก
      } catch (error) {
        console.error("Failed to load products:", error);
      } finally {
        this.loading = false; // ปิดสถานะการโหลด
      }
    },
  },
};
</script>
