<template>
    <div class="edit-product min-h-screen p-6 text-white">
      <h1 class="text-2xl font-bold mb-6">Manage Products</h1>
  
      <!-- Product Form -->
      <div class="bg-custom-gradient rounded-lg p-6 shadow-lg mb-6">
        <h2 class="text-xl font-bold mb-4">{{ isEditing ? "Edit Product" : "Add New Product" }}</h2>
        <form @submit.prevent="saveProduct">
          <div class="mb-4">
            <label class="block text-gray-300 mb-2">Name</label>
            <input
              v-model="product.name"
              type="text"
              class="w-full p-2 rounded bg-gray-800 border border-gray-700"
              required
            />
          </div>
          <div class="mb-4">
            <label class="block text-gray-300 mb-2">Role ID</label>
            <input
              v-model="product.roleId"
              type="text"
              class="w-full p-2 rounded bg-gray-800 border border-gray-700"
              placeholder="Enter Discord Role ID"
            />
          </div>
          <div class="mb-4">
            <label class="block text-gray-300 mb-2">Price</label>
            <input
              v-model="product.price"
              type="number"
              class="w-full p-2 rounded bg-gray-800 border border-gray-700"
              required
            />
          </div>
          <div class="mb-4">
            <label class="block text-gray-300 mb-2">Version</label>
            <input
              v-model="product.version"
              type="text"
              class="w-full p-2 rounded bg-gray-800 border border-gray-700"
              placeholder="Enter version (e.g., 1.0.0)"
              required
            />
          </div>
          <div class="mb-4">
            <label class="block text-gray-300 mb-2">YouTube URL</label>
            <input
              v-model="product.youtube"
              type="text"
              class="w-full p-2 rounded bg-gray-800 border border-gray-700"
            />
          </div>
          <div class="mb-4">
            <label class="block text-gray-300 mb-2">Image</label>
            <input
              type="file"
              @change="handleFileUpload"
              class="w-full p-2 rounded bg-gray-800 border border-gray-700"
              accept="image/*"
            />
          </div>
          <div class="mb-4">
            <label class="block text-gray-300 mb-2">Description</label>
            <textarea
              v-model="product.description"
              class="w-full p-2 rounded bg-gray-800 border border-gray-700"
            ></textarea>
          </div>
          <div class="mb-4">
            <label class="block text-gray-300 mb-2">Information</label>
            <textarea
              v-model="productInformation"
              class="w-full p-2 rounded bg-gray-800 border border-gray-700"
              placeholder="Enter information separated by new lines"
            ></textarea>
          </div>
          <button
            type="submit"
            class="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded"
          >
            {{ isEditing ? "Save Changes" : "Add Product" }}
          </button>
        </form>
      </div>
  
      <!-- Product List -->
      <div class="bg-custom-gradient rounded-lg p-6 shadow-lg">
        <h2 class="text-xl font-bold mb-4">Product List</h2>
        <div v-if="loading" class="text-center text-gray-400">Loading...</div>
        <ul v-else class="divide-y divide-gray-700">
          <li
            v-for="(prod, index) in products"
            :key="prod.name"
            class="flex justify-between items-center py-4"
          >
            <div>
              <h3 class="font-bold text-lg">{{ prod.name }}</h3>
              <p class="text-gray-400 text-sm">{{ prod.description }}</p>
            </div>
            <div class="flex items-center">
              <button
                @click="editProduct(index)"
                class="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded mr-2"
              >
                Edit
              </button>
              <button
                @click="deleteProduct(index)"
                class="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded"
              >
                Delete
              </button>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </template>
  
<script>
  import { fetchProducts, addProduct, updateProduct, deleteProduct } from "@/services/productsService";
  import {useToast} from 'vue-toast-notification';
  import 'vue-toast-notification/dist/theme-sugar.css';
  const apiUrl = process.env.VUE_APP_API_URL;
  import authService from "@/services/authService";
  export default {
    name: "EditProduct",
    data() {
      return {
        user: null,
        products: [], // เก็บข้อมูลสินค้า
        product: this.getEmptyProduct(), // สินค้าใหม่
        productInformation: "", // สำหรับแยกข้อมูล Information
        isEditing: false, // ระบุสถานะแก้ไข
        editIndex: null, // เก็บ index ของสินค้าที่กำลังแก้ไข
        loading: true, // สถานะการโหลดข้อมูล
      };
    },
    async mounted() {
      await this.checkLoginStatus();
      await this.loadProducts(); // โหลดข้อมูลเมื่อคอมโพเนนต์ถูก mount
    },
    methods: {
      async handleFileUpload(event) {
        const toast = useToast();
        const file = event.target.files[0]; // รับไฟล์จาก input
        if (!file) {
          toast.error("No file selected.");
          return;
        }

        try {
          const response = await fetch(`${apiUrl}/api/generate-signature`);
          if (!response.ok) throw new Error("Failed to fetch signature");

          const { signature, timestamp } = await response.json();
          const formData = new FormData();
          formData.append("file", file);
          formData.append("upload_preset", "Qubit API");
          formData.append("timestamp", timestamp);
          formData.append("signature", signature);
          formData.append("api_key", "634876621543333");

          const uploadResponse = await fetch("https://api.cloudinary.com/v1_1/df8o1j799/image/upload", {
            method: "POST",
            body: formData,
          });
          if (!uploadResponse.ok) throw new Error("Failed to upload image");

          const uploadResult = await uploadResponse.json();
          const imageUrl = uploadResult.secure_url;
          if (!imageUrl) throw new Error("การอัปโหลดรูปภาพล้มเหลว: ไม่มี secure_url");

          this.product.imagePath = imageUrl;
          toast.success("Image uploaded successfully!");
        } catch (error) {
          toast.error("An error occurred during the upload.");
          console.error("Upload failed:", error);
        }
      },
      getEmptyProduct() {
        return {
          name: "",
          roleId: "",
          version: "1.0.0",
          price: "",
          youtube: "",
          imagePath: "",
          description: "",
          information: [],
        };
      },
      async loadProducts() {
        try {
          this.products = await fetchProducts(); // ดึงข้อมูลจาก API
        } catch (error) {
          console.error("Failed to fetch products:", error);
        } finally {
          this.loading = false; // ปิดสถานะการโหลด
        }
      },
      async saveProduct() {
        const toast = useToast();
        try {
            this.product.information = this.productInformation.split("\n"); // แปลงข้อมูล Information

            if (!/^\d+\.\d+\.\d+([a-zA-Z][0-9]*)?$/.test(this.product.version)) {
              toast.error("Invalid version format. Use format like 1.0.0a.");
              return;
            }

            if (this.isEditing) {
            await updateProduct(this.product.name, this.product);
            toast.success("Product updated successfully!");
            } else {
            const newProduct = await addProduct(this.product); // เพิ่มสินค้าใน API
            this.products.push(newProduct); // เพิ่มใน UI
            toast.success("Product added successfully!");
            }

            await this.loadProducts(); // โหลดข้อมูลสินค้าใหม่
            this.resetForm(); // รีเซ็ตฟอร์ม
        } catch (error) {
            toast.error("Failed to save product. Please try again.");
            console.error("Failed to save product:", error);
        }
      },
      editProduct(index) {
        const selectedProduct = this.products[index];
        this.product = { ...selectedProduct };
        this.productInformation = this.product.information.join("\n");
        this.isEditing = true;
        this.editIndex = index;
      },
      async deleteProduct(index) {
        const toast = useToast();
        const productName = this.products[index].name;
  
        if (confirm(`Are you sure you want to delete "${productName}"?`)) {
          try {
            await deleteProduct(productName); // ลบสินค้าใน API
            this.products.splice(index, 1); // อัปเดตใน UI
            toast.success(`Product "${productName}" deleted successfully!`);
          } catch (error) {
            toast.error("Failed to delete product. Please try again.");
            console.error("Failed to delete product:", error);
          }
        }
      },
      resetForm() {
        this.product = this.getEmptyProduct();
        this.productInformation = "";
        this.isEditing = false;
        this.editIndex = null;
      },
      async checkLoginStatus() {
        try {
          this.user = await authService.getUser();
          await this.getUserData();
        } catch (error) {
          console.error("User not logged in:", error.message);
          authService.login();
        }
      },
      async getUserData() {
        try {
          const userData = await authService.getUserData();
          
          if (userData.role === "user") {
            this.$router.push("/");
          }
        } catch (error) {
          console.error("Failed to fetch user data:", error.message);
        }
      },
    },
  };
</script>
  
<style scoped>
  .edit-product {
    max-width: 1000px;
    width: 100%;
  }
</style>
  