<template>
  <div class="productdetail-content text-white min-h-screen p-6">
    <!-- Header -->
    <div class="flex items-center justify-between mb-8 animate-fade-in">
      <h1 class="text-2xl font-bold uppercase">{{ product?.name || "Product Not Found" }}</h1>
    </div>
    <!-- Main Content -->
    <div class="grid grid-cols-1 lg:grid-cols-3 gap-6">
      <!-- YouTube Video Embed -->
      <div
        class="relative col-span-2 bg-black rounded-2xl overflow-hidden shadow-lg border-6 border-transparent animate-slide-in-left"
        style="border-image: linear-gradient(to bottom, #1D1D1D 0%, #282828 100%); border-image-slice: 1;"
      >
        <!-- แสดงวิดีโอถ้าเป็น Desktop/Tablet -->
        <div class="hidden md:block w-full h-full relative">
          <iframe
            v-if="isValidYouTubeUrl(product?.youtube)"
            class="absolute top-0 left-0 w-full h-full rounded-2xl"
            :src="getYouTubeEmbedUrl(product.youtube)"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
          <div v-else class="flex items-center justify-center h-full text-gray-500">
            No Video Available
          </div>
        </div>
        
        <!-- บนมือถือให้แสดงปุ่มแทน -->
        <div class="md:hidden flex items-center justify-center h-[200px] bg-black rounded-2xl">
          <button
            v-if="isValidYouTubeUrl(product?.youtube)"
            @click="openYoutubeLink(product.youtube)"
            class="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          >
            ดูวิดีโอบน YouTube
          </button>
          <div v-else class="text-gray-500">No Video Available</div>
        </div>
      </div>

      <!-- Product Card -->
      <div class="bg-custom-gradient rounded-lg p-6 shadow-lg flex flex-col items-center animate-slide-in-right">
        <!-- Product Image -->
        <img
          :src="product?.imagePath"
          alt="Product Image"
          class="rounded-lg w-full h-40 object-cover mb-4 transform transition-transform duration-300 hover:scale-105"
        />
        <!-- Product Name -->
        <!-- <h2 class="text-xl font-bold mb-2 uppercase">{{ product?.name || "Product Name" }}</h2> -->
        <h1 class="text-2xl font-bold uppercase">
          {{ product?.name || "Product Not Found" }}
          <span v-if="product?.version" class="text-gray-400 text-sm">({{ product.version }})</span>
        </h1>
        <!-- Product Description -->
        <p class="text-gray-400 text-sm mb-4 font-kanit">{{ product?.description || "No description available." }}</p>
        <!-- Product Price -->
        <span class="text-green-400 font-bold text-lg mb-4">{{ product?.price || "0" }} POINT</span>
        <!-- Buy Button -->
        <button
          class="bg-green-500 hover:bg-green-600 text-white text-lg font-bold py-4 px-4 rounded w-full shadow-lg transform transition-transform duration-300 hover:scale-105"
          @click="handleBuy"
        >
          BUY NOW
        </button>

      </div>
    </div>

    <!-- Details Section -->
    <div v-if="product" class="bg-custom-gradient rounded-lg p-6 shadow-lg mt-6">
      <h2 class="text-2xl font-bold mb-4 text-white border-b pb-1 font-kanit">รายละเอียด</h2>
      <ul v-if="product.information?.length" class="list-disc list-inside space-y-2 font-kanit">
        <li v-for="(info, index) in product.information" :key="index">{{ info }}</li>
      </ul>
      <p v-else class="text-gray-500">No additional information available.</p>
    </div>
  </div>
</template>

<script>
import { fetchProducts } from "@/services/productsService";
import authService from "@/services/authService";
import {useToast} from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
const apiUrl = process.env.VUE_APP_API_URL;

export default {
  name: "ProductDetail",
  data() {
    return {
      product: null,
    };
  },
  async mounted() {
    await this.fetchProduct();
  },
  methods: {
    async fetchProduct() {
      try {
        const encodedName = this.$route.params.encodedName || ""; // ดึงค่า encodedName จาก URL
        const decodedName = atob(encodedName); // ถอดรหัส Base64 กลับเป็นชื่อสินค้า

        const products = await fetchProducts(); // ดึงข้อมูลจาก API
        
        this.product = products.find((p) => p.name === decodedName);
        if (!this.product) {
          console.error("Product not found for:", decodedName);
        }
      } catch (error) {
        console.error("Error decoding product name:", error);
        this.product = null; // กรณีเกิดข้อผิดพลาด
      }
    },
    getYouTubeEmbedUrl(youtubeUrl) {
      try {
        const videoId = youtubeUrl.split("v=")[1]?.split("&")[0];
        return `https://www.youtube.com/embed/${videoId}`;
      } catch {
        return null; // คืนค่า null กรณี URL ไม่ถูกต้อง
      }
    },
    isValidYouTubeUrl(youtubeUrl) {
      return youtubeUrl && youtubeUrl.includes("youtube.com/watch?v=");
    },
    openYoutubeLink(youtubeUrl) {
      window.open(youtubeUrl, "_blank");
    },
    async handleBuy() {
      try {
        const user = await authService.getUser();
        if (!user) {
          // หากไม่ได้ล็อกอิน Redirect ไปหน้าล็อกอิน
          authService.login();
          return;
        }

        const toast = useToast();

        // เรียก API /api/checkout เพื่อทำการซื้อสินค้า
        const checkoutResponse = await fetch(`${apiUrl}/api/checkout`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include", // ใช้ SESSION
          body: JSON.stringify({
            productName: this.product.name, // ส่ง ID ของสินค้าไปยัง Backend
          }),
        });

        if (!checkoutResponse.ok) {
          const errorData = await checkoutResponse.json();
          toast.error(errorData.error || "Point ของคุณไม่เพียงพอ");
          return;
        }

        const result = await checkoutResponse.json();
        toast.success(`คุณได้ซื้อสินค้า: ${this.product.name} สำเร็จ! เหลือ Point: ${result.remainingPoints}`);
      } catch (error) {
        const toast = useToast();
        toast.error("เกิดข้อผิดพลาดระหว่างการซื้อสินค้า กรุณาลองอีกครั้ง");
      }
    }
  },
};
</script>

<style>
.productdetail-content {
  max-width: 1100px; /* ขยายขนาดเพื่อให้เหมาะสม */
  width: 100%;
}

.animate-fade-in {
  animation: fadeIn 0.5s ease-in-out forwards;
}

.animate-slide-in-left {
  animation: slideInLeft 0.5s ease-in-out forwards;
}

.animate-slide-in-right {
  animation: slideInRight 0.5s ease-in-out forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideInLeft {
  from {
    opacity: 0;
    transform: translateX(-50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slideInRight {
  from {
    opacity: 0;
    transform: translateX(50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}
</style>
