const API_URL_BACKEND = process.env.VUE_APP_API_URL;

export async function getCsrfToken() {
  try {
    const response = await fetch(`${API_URL_BACKEND}/api/csrf-token`, {
      method: "GET",
      credentials: "include",
    });

    if (!response.ok) {
      throw new Error(`Failed to fetch CSRF Token: ${response.statusText}`);
    }

    const data = await response.json();
    return data.csrfToken;
  } catch (error) {
    console.error("Failed to fetch CSRF Token:", error);
    return null;
  }
}