import { createStore } from "vuex";

export default createStore({
  state: {
    userBalance: 0,
  },
  mutations: {
    setUserBalance(state, balance) {
      state.userBalance = balance;
    },
  },
  actions: {
    updateUserBalance({ commit }, balance) {
      commit("setUserBalance", balance);
    },
  },
  getters: {
    userBalance: (state) => state.userBalance,
  },
});
