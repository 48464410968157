<template>
  <div>
    <!-- ส่วนหัว -->
    <div class="section-two text-white flex flex-col items-center text-center">
      <h2 class="text-4xl font-bold mb-4">QUBIT SCRIPT</h2>
      <h3 class="text-3xl font-bold mb-6">FIVEM</h3>
      <p class="text-4xl font-bold mb-4 text-green-400">ALL PRODUCT</p>
    </div>

    <!-- รายการสินค้า -->
    <div class="products-content">
      <div v-if="loading" class="text-center text-gray-400">Loading...</div>
      <div v-else-if="products.length > 0" class="container mx-auto py-2">
        <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
          <ProductCard
            v-for="product in products"
            :key="product.name"
            :name="product.name"
            :description="product.description"
            :price="product.price"
            :image="product.imagePath"
          />
        </div>
      </div>
      <div v-else class="text-center text-gray-400">No products available.</div>
    </div>
  </div>
</template>

<script>
import ProductCard from "@/components/ProductCard.vue";
import { fetchProducts } from "@/services/productsService";

export default {
  name: "ProductsPage",
  components: {
    ProductCard,
  },
  data() {
    return {
      products: [], // เก็บข้อมูลสินค้า
      loading: true,
    };
  },
  async mounted() {
    await this.loadProducts(); // โหลดข้อมูลเมื่อคอมโพเนนต์ถูก mount
  },
  methods: {
    async loadProducts() {
      try {
        const data = await fetchProducts(); // ดึงข้อมูลจาก API
        this.products = data; // เก็บข้อมูลใน products
      } catch (error) {
        console.error("Failed to load products:", error);
      } finally {
        this.loading = false; // ปิดสถานะการโหลด
      }
    },
  },
};
</script>

<style scoped>
.products-content {
  max-width: 1000px; /* จำกัดความกว้าง */
  width: 100%; /* ยืดความกว้างเต็มที่ */
}
</style>
