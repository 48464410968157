<template>
  <div class="text-white flex flex-col items-center text-center px-4 md:px-0">
    <h2 class="text-3xl md:text-4xl font-bold mb-4">QUBIT SCRIPT</h2>
    <h3 class="text-2xl md:text-3xl font-bold mb-6">FIVEM</h3>
    <p class="text-3xl md:text-4xl font-bold mb-4 text-green-400">STATEMENT</p>
  </div>
  <div class="statement-content min-h-screen flex flex-col md:flex-row items-start gap-4 md:gap-8 px-4 md:px-0">
    <!-- Sidebar -->
    <ProfileSummary/>

    <!-- Main Content -->
    <div class="main-content flex-1 bg-custom-gradient text-white p-4 md:p-8 rounded-lg shadow-lg">
      <div class="header mb-6 flex items-center justify-between">
        <h2 class="text-xl md:text-2xl font-bold">PAYMENT HISTORY</h2>
      </div>
      <div v-if="isLoading" class="flex flex-col justify-center items-center min-h-[300px]">
        <div class="spinner"></div>
      </div>
      <div v-else class="overflow-x-auto">
        <table class="w-full text-left text-xs md:text-sm">
          <thead>
            <tr class="bg-teal-900">
              <th class="px-2 md:px-4 py-2">ID</th>
              <th class="px-2 md:px-4 py-2">DATE-TIME</th>
              <th class="px-2 md:px-4 py-2">POINT</th>
              <th class="px-2 md:px-4 py-2">SLIP</th>
              <th class="px-2 md:px-4 py-2">STATUS</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="statement in statements" :key="statement.id" class="hover:bg-white hover:bg-opacity-10">
              <td class="px-2 md:px-4 py-4">#{{ statement.id }}</td>
              <td class="px-2 md:px-4 py-4 whitespace-nowrap">{{ formatDate(statement.date) }}</td>
              <td class="px-2 md:px-4 py-4">{{ statement.point }}</td>
              <td class="px-2 md:px-4 py-4">
                <!-- View Slip Link -->
                <button
                  @click="openImage(statement.urlslip)"
                  class="text-blue-400 hover:underline"
                >
                  View Slip
                </button>
              </td>
              <td class="px-2 md:px-4 py-4">
                <span
                  :class="{
                    'text-yellow-400': statement.status === 'pending',
                    'text-green-400': statement.status === 'confirmed',
                    'text-red-400': statement.status === 'cancelled',
                  }"
                >
                  {{ statement.status.toUpperCase() }}
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <!-- Modal for Viewing Full Image -->
      <div
        v-if="showImageModal"
        class="fixed top-0 left-0 w-full h-full bg-black bg-opacity-80 flex justify-center items-center z-50"
        @click="closeImage"
      >
        <div class="relative">
          <img
            :src="modalImageUrl"
            alt="Full Slip Image"
            class="max-w-[90vw] md:max-w-[50vw] max-h-[90vh] md:max-h-[50vh] object-contain rounded-lg shadow-lg"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProfileSummary from "../components/ProfileSummary.vue";
import authService from "@/services/authService";
const apiUrl = process.env.VUE_APP_API_URL;

export default {
  name: "StatementPage",
  components: { ProfileSummary },
  data() {
    return {
      user: null,
      showImageModal: false,
      modalImageUrl: "",
      statements: [],
      isLoading: false,
    };
  },
  methods: {
    async getUser() {
      this.isLoading = true;
      try {
        this.user = await authService.getUser();
        this.getPaymentHistory();
      } catch (error) {
        authService.login();
      }
    },
    async getPaymentHistory() {
      try {
        const response = await fetch(`${apiUrl}/api/statement?user_id=${this.user.id}`, {
          credentials: "include",
        });
        if (!response.ok) throw new Error("Failed to fetch payment history");

        this.statements = await response.json();
      } catch (error) {
        console.error("Failed to fetch payment history:", error.message);
      } finally {
        this.isLoading = false;
      }
    },
    openImage(url) {
      this.modalImageUrl = url;
      this.showImageModal = true;
    },
    closeImage() {
      this.showImageModal = false;
    },
    formatDate(isoDate) {
      if (!isoDate) return "N/A";
      const options = { year: "numeric", month: "2-digit", day: "2-digit", hour: "2-digit", minute: "2-digit", second: "2-digit", hour12: false };
      return new Date(isoDate).toLocaleString("en-US", options);
    },
  },
  mounted() {
    this.getUser();
  },
};
</script>

<style scoped>
.statement-content {
  max-width: 1200px;
  width: 100%;
}

table th {
  font-size: 0.8rem;
  font-weight: bold;
  text-transform: uppercase;
  color: #ffffff;
}

table td {
  font-size: 0.8rem;
}

table tbody tr:hover td {
  background: rgba(255, 255, 255, 0.1);
}

.spinner {
  border: 4px solid rgba(255, 255, 255, 0.2);
  border-top: 4px solid #4caf50;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin: auto;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
