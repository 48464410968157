<template>
  <div class="sidebar bg-custom-gradient text-white p-6 rounded-lg shadow-lg w-64 flex-shrink-0">
    <div v-if="isLoading" class="flex justify-center items-center h-full">
      <p class="text-green-400 font-bold">Loading...</p>
    </div>
    <div v-else class="flex flex-col items-center">
    <div class="profile-image rounded-full overflow-hidden border-4 border-green-400 w-32 h-32">
        <img
        :src="user ? user.avatar && `https://cdn.discordapp.com/avatars/${user.id}/${user.avatar}.png` : 'https://via.placeholder.com/150'"
        alt="User Profile"
        class="w-full h-full object-cover"
        />
    </div>
    <h2 class="mt-4 text-lg font-bold">NAME : {{ user ? user.username : 'Guest' }}</h2>
    <p class="text-green-400 text-lg font-semibold">POINT : {{ userBalance  }}</p>
    <div class="mt-4 text-sm">
        <p>Purchased Products : <span class="font-bold">{{ user?.purchased || 0 }}</span></p>
    </div>
    </div>
</div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import authService from "@/services/authService";

export default {
  name: "ProfileSummary",
  data() {
    return {
      user: null,
      isLoading: true,
    };
  },
  computed: {
    ...mapGetters(["userBalance"]),
  },
  methods: {
    ...mapActions(["updateUserBalance"]),
    async checkLoginStatus() {
      try {
        this.user = await authService.getUser();
        await this.getUserData();
      } catch (error) {
        console.error("User not logged in:", error.message);
        authService.login();
      }
    },
    async getUserData() {
      try {
        const userData = await authService.getUserData();
        this.user = { ...this.user, point: userData.point || 0, purchased: userData.purchased || 0 };
      } catch (error) {
        console.error("Failed to fetch user data:", error.message);
      } finally {
        this.isLoading = false;
      }
    },
  },
  async mounted() {
    try {
      await this.checkLoginStatus();
    } catch (error) {
      console.error("Failed to initialize Dashboard:", error.message);
    }
  },
};
</script>