<template>
  <div class="loader-container">
    <div class="spinner">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AppLoader",
};
</script>

<style scoped>
/* Loader Container */
.loader-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8); /* พื้นหลังสีดำโปร่งแสง */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

/* Spinner */
.spinner {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 80px;
  height: 80px;
  position: relative;
}

.spinner div {
  width: 15px;
  height: 15px;
  background-color: #009F92;
  border-radius: 50%;
  animation: bounce 1.2s infinite ease-in-out;
  position: absolute;
}

.spinner div:nth-child(1) {
  animation-delay: -0.3s;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
}

.spinner div:nth-child(2) {
  animation-delay: -0.6s;
  left: 0;
  top: 50%;
  transform: translate(-50%, -50%);
}

.spinner div:nth-child(3) {
  animation-delay: -0.9s;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 50%);
}

.spinner div:nth-child(4) {
  animation-delay: -1.2s;
  right: 0;
  top: 50%;
  transform: translate(50%, -50%);
}

/* Bounce Animation */
@keyframes bounce {
  0%, 100% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.5);
    opacity: 0.7;
  }
}
</style>
