<template>
  <div class="text-white flex flex-col items-center text-center">
    <h2 class="text-4xl font-bold mb-4">QUBIT SCRIPT</h2>
    <h3 class="text-3xl font-bold mb-6">FIVEM</h3>
    <p class="text-4xl font-bold mb-4 text-green-400">DASHBOARD</p>
  </div>
  <div class="dashboard-content container mx-auto min-h-screen flex flex-col md:flex-row items-start gap-8 px-4">
    <ProfileSummary/>
    <!-- Main Content -->
    <div class="main-content flex-1 bg-custom-gradient text-white p-4 md:p-8 rounded-lg shadow-lg">
      <div class="header mb-6 flex items-center justify-between">
        <h2 class="text-xl md:text-2xl font-bold">PRODUCTS</h2>
      </div>
      <div v-if="isLoading" class="flex flex-col justify-center items-center min-h-[300px]">
        <div class="spinner"></div>
      </div>

      <!-- เพิ่ม overflow-x-auto และใช้ text-xs บนมือถือ -->
      <div v-else class="overflow-x-auto">
        <table class="w-full text-left text-xs md:text-sm">
          <thead>
            <tr class="bg-teal-900">
              <th class="px-2 py-2 md:px-4">ID</th>
              <th class="px-2 py-2 md:px-4">SCRIPT</th>
              <th class="px-2 py-2 md:px-4">DATA-TIME</th>
              <th class="px-2 py-2 md:px-4">IP-SERVER</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(product, index) in products" :key="product.id" class="hover:bg-white hover:bg-opacity-10">
              <td class="px-2 py-4 md:px-4">#{{ product.id }}</td>
              <td class="px-2 py-4 md:px-4 flex items-center gap-2">
                <span class="text-green-400">&#9679;</span>
                {{ product.name }}
              </td>
              <td class="px-2 py-2 md:px-4">{{ formatDate(product.date) }}</td>
              <td class="px-2 py-2 md:px-4">
                <div class="flex flex-col md:flex-row items-start md:items-center gap-2">
                  <!-- Input field or IP display -->
                  <div class="relative w-full md:w-200px">
                    <div v-if="editingIndex === index" class="flex flex-col md:flex-row items-start md:items-center gap-2">
                      <input
                        type="text"
                        v-model="editIpValue"
                        class="bg-gray-900 text-white border border-gray-700 rounded-lg px-2 py-1 w-full focus:outline-none"
                        placeholder="Enter IP"
                      />
                      <div class="flex gap-1">
                        <button
                          @click="saveIp(index)"
                          class="text-green-400 hover:text-green-500"
                          title="Save IP"
                        >
                          <i class="fas fa-check"></i>
                        </button>
                        <button
                          @click="cancelEdit"
                          class="text-red-400 hover:text-red-500"
                          title="Cancel"
                        >
                          <i class="fas fa-times"></i>
                        </button>
                      </div>
                    </div>
                    <div v-else class="flex items-center gap-2">
                      <span v-if="product.ip" class="break-words">{{ product.ip }}</span>
                      <button
                        v-if="product.ip"
                        @click="editIp(index)"
                        class="text-blue-400 hover:text-blue-500"
                        title="Edit IP"
                      >
                        <i class="fas fa-pen"></i>
                      </button>
                      <button
                        v-else
                        @click="editIp(index)"
                        class="text-green-400 hover:underline"
                      >
                        PASTE IP
                      </button>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import ProfileSummary from "../components/ProfileSummary.vue";
const apiUrl = process.env.VUE_APP_API_URL;
import authService from "@/services/authService";
import {useToast} from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';

export default {
  name: "DashboardPage",
  components: { ProfileSummary },
  data() {
    return {
      user: null,
      editingIndex: null,
      editIpValue: "",
      products: [],
      isLoading: false,
    };
  },
  methods: {
    async fetchProducts() {
      try {
        if (!this.user || !this.user.id) {
          throw new Error("User is not logged in or user data is missing");
        }

        const response = await fetch(`${apiUrl}/api/purchases?user_id=${this.user.id}`, {
          method: "GET",
          credentials: "include",
        });

        if (!response.ok) {
          throw new Error("Failed to fetch products");
        }

        const productsData = await response.json();
        this.products = productsData;
      } catch (error) {
        console.error("Failed to fetch products:", error.message);
      } finally {
        this.isLoading = false;
      }
    },
    formatDate(isoDate) {
      if (!isoDate) return "N/A";
      const options = { year: "numeric", month: "2-digit", day: "2-digit", hour: "2-digit", minute: "2-digit", second: "2-digit", hour12: false };
      return new Date(isoDate).toLocaleString("en-US", options);
    },
    editIp(index) {
      this.editingIndex = index;
      this.editIpValue = this.products[index].ip || "";
    },
    async saveIp(index) {
      try {
        const toast = useToast();
        if (this.editIpValue.trim() === "") {
          return;
        }

        const product = this.products[index];

        const response = await fetch(`${apiUrl}/api/update-ip`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            id: product.id,
            ip: this.editIpValue,
          }),
        });

        if (!response.ok) {
          const errorData = await response.json();
          if (errorData.remaining_minutes) {
            toast.error(`ไม่สามารถอัปเดต IP ได้ในขณะนี้ กรุณารออีก ${errorData.remaining_minutes} นาที`);
          } else {
            toast.error(errorData.error || "Failed to update IP");
          }
          return;
        }

        this.products[index].ip = this.editIpValue;
        this.editingIndex = null;
      } catch (error) {
        console.error("Failed to update IP:", error.message);
      }
    },
    cancelEdit() {
      this.editingIndex = null;
      this.editIpValue = "";
    },
    async checkLoginStatus() {
      this.isLoading = true;
      try {
        this.user = await authService.getUser();
      } catch (error) {
        console.error("User not logged in:", error.message);
        authService.login();
      }
    },
  },
  async mounted() {
    try {
      await this.checkLoginStatus();
      await this.fetchProducts();
    } catch (error) {
      console.error("Failed to initialize Dashboard:", error.message);
    }
  },
};
</script>

<style scoped>
.dashboard-content {
  max-width: 1200px;
  width: 100%;
}

.profile-image {
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.3);
}

table th {
  font-size: 0.9rem;
  font-weight: bold;
  text-transform: uppercase;
  color: #ffffff;
}

table td {
  font-size: 0.9rem;
}

table tbody tr:hover td {
  background: rgba(255, 255, 255, 0.1);
}

.relative.w-200px {
  width: 200px;
}

input {
  line-height: normal;
}

.spinner {
  border: 4px solid rgba(255, 255, 255, 0.2);
  border-top: 4px solid #4caf50;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin: auto;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
