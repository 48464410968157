<template>
  <div class="app-container">
    <AppLoader v-if="isLoading" />
    <div class="navbar-container">
        <NavBar class="navbar-content" />
    </div>
    <router-view />
    <div class="footer-container">
        <div class="footer-content">
        <Footer />
        </div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref } from "vue";
import { useRouter } from "vue-router";
import AppLoader from "./components/Loader.vue";
import NavBar from "./components/NavBar.vue";
import Footer from './components/Footer.vue';

export default {
  name: "App",
  components: { AppLoader, NavBar, Footer },
  setup() {
    const isLoading = ref(false);

    const startLoading = () => {
      isLoading.value = true;
    };

    const stopLoading = () => {
      isLoading.value = false;
    };

    onMounted(() => {
      // ใช้งาน Navigation Guards
      const router = useRouter();

      router.beforeEach((to, from, next) => {
        startLoading(); // เริ่มแสดง Loader
        next();
      });

      router.afterEach(() => {
        stopLoading();
      });
    });

    return {
      isLoading,
    };
  },
};
</script>

<style scoped>
/* Reset styles */
::v-deep(html, body, div, main, .app-container) {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* App Container */
.app-container {
  background: linear-gradient(to bottom, #090909 0%, #005652 100%);
  min-height: 101vh; /* ครอบคลุมทั้งหน้าจอ */
  display: flex;
  flex-direction: column;
  align-items: center; /* จัดเนื้อหาให้อยู่ตรงกลางแนวแกน X */
  gap: 20px;
}

.navbar-container {
  position: sticky; /* กำหนดให้เลื่อนตาม */
  top: 0; /* ติดอยู่ที่ด้านบน */
  z-index: 1000; /* ให้ซ้อนทับเนื้อหาอื่น */
  background-color: #1C1C1C; /* สีพื้นหลัง */
  width: 100%; /* ยาวเต็มหน้าจอ */
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2); /* เพิ่มเงา */
}

/* Navbar Content */
.navbar-content {
  max-width: 1200px; /* จำกัดความกว้างของเนื้อหา */
  margin: 0 auto; /* จัดเนื้อหาให้อยู่ตรงกลาง */
  padding: 10px 20px; /* Padding ด้านใน */
  display: flex; /* ใช้ Flexbox */
  justify-content: space-between; /* กระจายเนื้อหาออกซ้าย-ขวา */
  align-items: center; /* จัดแนวตั้งให้ตรงกลาง */
  box-sizing: border-box;
}

.footer-container {
  width: 100%;
  border-top: 1px solid #00665A;
}

.footer-content {
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
}
</style>

