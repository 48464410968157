<template>
  <div class="text-white flex flex-col items-center text-center fade-in">
    <h2 class="text-4xl font-bold mb-4 slide-up">QUBIT SCRIPT</h2>
    <h3 class="text-3xl font-bold mb-6 slide-up" style="animation-delay: 0.2s;">FIVEM</h3>
    <p class="text-2xl mb-4 font-kanit slide-up" style="animation-delay: 0.4s;">
      พร้อมสนับสนุนการพัฒนาและยกระดับการใช้งาน
    </p>
    <p class="text-2xl mb-6 font-kanit slide-up" style="animation-delay: 0.6s;">
      สนับสนุนหลังการขายตลอดอายุการใช้งาน
    </p>
  </div>
</template>

<script>
export default {
  name: "FeatureSection",
};
</script>

<style scoped>
/* Fade-in effect for the container */
.fade-in {
  opacity: 0;
  animation: fadeIn 1s ease-in-out forwards;
}

/* Slide-up effect for each element */
.slide-up {
  opacity: 0;
  transform: translateY(20px);
  animation: slideUp 1s ease-in-out forwards;
}

/* Animation Keyframes */
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes slideUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
</style>
