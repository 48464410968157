<template>
  <section class="py-8">
    <div class="container mx-auto grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-6">
      <!-- การ์ด 1 -->
      <div class="bg-custom-gradient rounded-lg shadow-md p-6 flex flex-col items-center text-center">
        <i class="fas fa-scroll text-neutral-400 text-4xl mb-4"></i>
        <p class="text-green-400 text-xl font-bold random-number visible">
          {{ isLoading ? randomStats.products : stats.products }}
        </p>
        <p class="text-white text-sm uppercase tracking-wider">Products</p>
      </div>

      <!-- การ์ด 2 -->
      <div class="bg-custom-gradient rounded-lg shadow-md p-6 flex flex-col items-center text-center">
        <i class="fas fa-store text-neutral-400 text-4xl mb-4"></i>
        <p class="text-green-400 text-xl font-bold random-number visible">
          {{ isLoading ? randomStats.purchases : stats.purchases }}
        </p>
        <p class="text-white text-sm uppercase tracking-wider">Purchases</p>
      </div>

      <!-- การ์ด 3 -->
      <div class="bg-custom-gradient rounded-lg shadow-md p-6 flex flex-col items-center text-center">
        <i class="fas fa-users text-neutral-400 text-4xl mb-4"></i>
        <p class="text-green-400 text-xl font-bold random-number visible">
          {{ isLoading ? randomStats.clients : stats.clients }}
        </p>
        <p class="text-white text-sm uppercase tracking-wider">Clients</p>
      </div>

      <!-- การ์ด 4 -->
      <div class="bg-custom-gradient rounded-lg shadow-md p-6 flex flex-col items-center text-center">
        <i class="fas fa-crown text-neutral-400 text-4xl mb-4"></i>
        <p class="text-green-400 text-xl font-bold random-number visible">
          {{ isLoading ? randomStats.members : stats.members }}
        </p>
        <p class="text-white text-sm uppercase tracking-wider">Members</p>
      </div>
    </div>
  </section>
</template>

<script>
const apiUrl = process.env.VUE_APP_API_URL;

export default {
  name: "StatsSection",
  data() {
    return {
      stats: {
        products: 0,
        purchases: 0,
        clients: 0,
        members: 0,
      },
      isLoading: true, // ตัวแปรสำหรับตรวจสอบการโหลด
      randomStats: {
        products: 0,
        purchases: 0,
        clients: 0,
        members: 0,
      },
      intervalId: null, // เก็บค่า setInterval เพื่อหยุดเมื่อโหลดเสร็จ
    };
  },
  mounted() {
    this.startRandomNumbers();
    this.fetchStats();
  },
  methods: {
    startRandomNumbers() {
      this.intervalId = setInterval(() => {
        this.animateNumbers(); // เพิ่มการจัดการคลาสสำหรับแอนิเมชัน
        this.randomStats = {
          products: Math.floor(Math.random() * 100),
          purchases: Math.floor(Math.random() * 1000),
          clients: Math.floor(Math.random() * 500),
          members: Math.floor(Math.random() * 100000),
        };
      }, 100); // สุ่มทุก 100ms
    },
    animateNumbers() {
      // เพิ่มเอฟเฟกต์การเปลี่ยนแปลง
      const elements = document.querySelectorAll(".random-number");
      elements.forEach((el) => {
        el.classList.remove("visible"); // ซ่อนตัวเลขเก่า
        el.classList.add("down"); // เพิ่มเอฟเฟกต์เลื่อนลง
        setTimeout(() => {
          el.classList.remove("down");
          el.classList.add("visible"); // แสดงตัวเลขใหม่พร้อมเลื่อนขึ้น
        }, 100);
      });
    },
    async fetchStats() {
      try {
        const response = await fetch(`${apiUrl}/api/statistics`);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        this.stats = data;
        this.isLoading = false; // ข้อมูลโหลดเสร็จ
        clearInterval(this.intervalId); // หยุดการสุ่ม
      } catch (error) {
        console.error("Error fetching statistics:", error);
      }
    },
  },
};
</script>

<style scoped>
/* ตัวเลขเปลี่ยนอย่างนุ่มนวล */
.random-number {
  transition: transform 0.3s ease, opacity 0.3s ease;
  display: inline-block; /* ช่วยให้ใช้งาน transition ได้ดีขึ้น */
}

.random-number.up {
  transform: translateY(-10px);
  opacity: 0;
}

.random-number.down {
  transform: translateY(10px);
  opacity: 0;
}

.random-number.visible {
  transform: translateY(0);
  opacity: 1;
}
</style>