import { createRouter, createWebHistory } from 'vue-router';
import HomePage from '../views/Home.vue';
import ProductsPage from '../views/Products.vue';
import ProductDetail from "../views/ProductDetail.vue";
import DashboardPage from "../views/Dashboard.vue";
import PaymentPage from "../views/Payment.vue";
import StatementPage from "../views/Statement.vue";
import EditproductPage from "../views/EditProduct.vue";

const routes = [
  {
    path: '/',
    name: 'HomePage',
    component: HomePage,
  },
  {
    path: '/products',
    name: 'Products',
    component: ProductsPage,
  },
  {
    path: "/products/:encodedName",
    name: "ProductDetail",
    component: ProductDetail,
  },
  {
    path: "/dashboard",
    name: "DashboardPage",
    component: DashboardPage,
  },
  {
    path: "/payment",
    name: "PaymentPage",
    component: PaymentPage,
  },
  {
    path: "/statement",
    name: "StatementPage",
    component: StatementPage,
  },
  {
    path: "/editproduct",
    name: "EditproductPage",
    component: EditproductPage,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition; // Return to saved position if available
    }
    return { top: 0, left: 0 }; // Scroll to top-left of the page
  },
});

export default router;
