<template>
  <footer class="footer-container">
    <div class="container mx-auto py-4 flex justify-between items-center">
      <!-- ข้อความลิขสิทธิ์ -->
      <p class="text-white text-sm">
        Copyright © 2024 Qubit Developer. All rights reserved.
      </p>

      <!-- ไอคอน Terms -->
      <router-link to="#" class="text-white text-sm flex items-center hover:text-green-400">
        <i class="fas fa-file-contract mr-2"></i> Terms
      </router-link>
    </div>
  </footer>
</template>

<script>
export default {
  name: "AppFooter",
};
</script>

<style scoped>
</style>
