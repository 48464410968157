const API_URL = `${process.env.VUE_APP_API_URL}/api/products`; // URL ของ API
const CACHE_KEY = "productsCache";
const CACHE_EXPIRY_KEY = "productsCacheExpiry";
const CACHE_EXPIRY_TIME = 10 * 60 * 1000; // 10 นาที
import { getCsrfToken } from "./csrfService";

/**
 * ดึงข้อมูลสินค้าทั้งหมดจาก API พร้อมใช้แคช
 * @returns {Promise<Array>} - ข้อมูลสินค้าจาก API หรือแคช
 */
export async function fetchProducts() {
  try {
    // ตรวจสอบแคชใน Local Storage
    const cachedData = localStorage.getItem(CACHE_KEY);
    const cacheExpiry = localStorage.getItem(CACHE_EXPIRY_KEY);

    if (cachedData && cacheExpiry && Date.now() < parseInt(cacheExpiry, 10)) {
      return JSON.parse(cachedData);
    }

    const response = await fetch(API_URL, {
      method: "GET",
      credentials: "include",
    });
    if (!response.ok) {
      throw new Error(`Failed to fetch products: ${response.statusText}`);
    }

    const data = await response.json();

    // เก็บข้อมูลลงใน Local Storage พร้อมเวลาหมดอายุ
    localStorage.setItem(CACHE_KEY, JSON.stringify(data));
    localStorage.setItem(CACHE_EXPIRY_KEY, Date.now() + CACHE_EXPIRY_TIME);

    return data;
  } catch (error) {
    console.error("Error fetching products:", error);
    throw error;
  }
}

/**
 * เพิ่มสินค้าใหม่ผ่าน API พร้อมอัปเดตแคช
 * @param {Object} product - สินค้าใหม่ที่ต้องการเพิ่ม
 * @returns {Promise<Object>} - ผลลัพธ์หลังการเพิ่มสินค้า
 */
export async function addProduct(product) {
  try {
    const csrfToken = await getCsrfToken();

    const response = await fetch(API_URL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": csrfToken,
      },
      body: JSON.stringify(product),
      credentials: "include",
    });
    if (!response.ok) {
      throw new Error(`Failed to add product: ${response.statusText}`);
    }

    const newProduct = await response.json();
    
    // อัปเดตแคชใน Local Storage
    const cachedData = localStorage.getItem(CACHE_KEY);
    if (cachedData) {
      const products = JSON.parse(cachedData);
      products.push(newProduct);
      localStorage.setItem(CACHE_KEY, JSON.stringify(products));
      localStorage.setItem(CACHE_EXPIRY_KEY, Date.now() + CACHE_EXPIRY_TIME);
    }

    return newProduct;
  } catch (error) {
    console.error("Error adding product:", error);
    throw error;
  }
}

/**
 * ลบสินค้าผ่าน API พร้อมอัปเดตแคช
 * @param {string} productName - ชื่อสินค้าที่ต้องการลบ
 * @returns {Promise<Object>} - ผลลัพธ์หลังการลบสินค้า
 */
export async function deleteProduct(productName) {
  try {
    const csrfToken = await getCsrfToken();

    const response = await fetch(`${API_URL}/${encodeURIComponent(productName)}`, {
      method: "DELETE",
      headers: {
        "X-CSRF-Token": csrfToken,
      },
      credentials: "include",
    });
    if (!response.ok) {
      throw new Error(`Failed to delete product: ${response.statusText}`);
    }

    const result = await response.json();

    // อัปเดตแคชใน Local Storage
    const cachedData = localStorage.getItem(CACHE_KEY);
    if (cachedData) {
      const products = JSON.parse(cachedData).filter((p) => p.name !== productName);
      localStorage.setItem(CACHE_KEY, JSON.stringify(products));
      localStorage.setItem(CACHE_EXPIRY_KEY, Date.now() + CACHE_EXPIRY_TIME);
    }

    return result;
  } catch (error) {
    console.error("Error deleting product:", error);
    throw error;
  }
}

/**
 * แก้ไขสินค้าใน API และอัปเดตแคช
 * @param {string} productName - ชื่อสินค้าที่ต้องการแก้ไข
 * @param {Object} updatedProduct - ข้อมูลสินค้าใหม่ที่ต้องการอัปเดต
 * @returns {Promise<Object>} - ผลลัพธ์หลังการอัปเดตสินค้า
 */
export async function updateProduct(productName, updatedProduct) {
  try {
    const csrfToken = await getCsrfToken();
    console.log("Updating product:", productName, updatedProduct);

    const response = await fetch(`${API_URL}/${encodeURIComponent(productName)}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": csrfToken,
      },
      body: JSON.stringify(updatedProduct),
      credentials: "include",
    });
    if (!response.ok) {
      throw new Error(`Failed to update product: ${response.statusText}`);
    }

    const result = await response.json();

    // อัปเดตแคชใน Local Storage
    const cachedData = localStorage.getItem(CACHE_KEY);
    if (cachedData) {
      const products = JSON.parse(cachedData);
      const productIndex = products.findIndex((p) => p.name === productName);

      if (productIndex !== -1) {
        products[productIndex] = { ...products[productIndex], ...updatedProduct };
        localStorage.setItem(CACHE_KEY, JSON.stringify(products));
        localStorage.setItem(CACHE_EXPIRY_KEY, Date.now() + CACHE_EXPIRY_TIME);
      }
    }

    return result;
  } catch (error) {
    console.error("Error updating product:", error);
    throw error;
  }
}
