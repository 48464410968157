<template>
  <div class="text-white flex flex-col items-center text-center">
    <h2 class="text-3xl md:text-4xl font-bold mb-4">QUBIT SCRIPT</h2>
    <h3 class="text-2xl md:text-3xl font-bold mb-6">FIVEM</h3>
    <p class="text-3xl md:text-4xl font-bold mb-4 text-green-400">PAYMENT</p>
  </div>
  <div class="payment-content min-h-screen flex flex-col md:flex-row items-start gap-4 md:gap-8 px-4 md:px-0">
    <!-- Sidebar -->
    <ProfileSummary/>

    <div class="main-content bg-custom-gradient text-white p-4 md:p-8 rounded-lg shadow-lg w-full md:grid md:grid-cols-2 gap-4 h-auto md:h-[700px]">
      <!-- Section 1: QR Code -->
      <div class="flex flex-col items-center mb-8 md:mb-0">
        <h3 class="text-lg font-bold mb-2 font-kanit">ช่องทางการชำระเงิน</h3>
        <!-- Payment Method Selection -->
        <div class="mb-4 flex gap-4">
          <button
            @click="selectPaymentMethod('bank')"
            :class="{
              'bg-green-500': selectedPayment === 'bank',
              'bg-gray-300': selectedPayment !== 'bank',
            }"
            class="py-2 px-6 md:px-10 rounded font-kanit transition text-sm md:text-base"
          >
            ธนาคาร
          </button>
          <button
            @click="selectPaymentMethod('wallet')"
            :class="{
              'bg-green-500': selectedPayment === 'wallet',
              'bg-gray-300': selectedPayment !== 'wallet',
            }"
            class="py-2 px-6 md:px-10 rounded font-kanit transition text-sm md:text-base"
          >
            วอเลท
          </button>
        </div>
        <!-- QR Code Display -->
        <div class="bg-white p-1 rounded-lg shadow-md max-w-xs w-full">
          <img
            v-if="selectedPayment === 'bank'"
            src="../assets/BankQRCode.png"
            alt="Bank QR Code"
            class="w-full object-contain rounded-lg"
          />
          <img
            v-else
            src="../assets/WalletQRCode.png"
            alt="Wallet QR Code"
            class="w-full object-contain rounded-lg"
          />
        </div>
      </div>

      <!-- Section 2: Form and Upload -->
      <div class="flex flex-col items-center">
        <!-- Input Form -->
        <div class="mb-8 w-full max-w-md mx-auto flex flex-col items-center">
          <h3 class="text-lg font-bold mb-4 text-center font-kanit">กรุณากรอกจำนวนเงิน</h3>
          <div class="relative w-full max-w-xs mx-auto">
            <input
              type="number"
              placeholder="กรอกจำนวนเงิน"
              v-model.number="amount"
              @input="calculatePoints"
              class="p-3 rounded-lg shadow-sm text-gray-700 w-full text-center border-none focus:outline-none appearance-none text-sm md:text-base"
            />
            <span class="absolute right-4 top-1/2 transform -translate-y-1/2 text-gray-500 font-bold text-sm md:text-base">
              บาท
            </span>
          </div>
          <p class="mt-2 text-green-400 font-bold text-xs md:text-sm font-kanit">
              คุณจะได้รับ {{ points }} Points
          </p>
          <!-- Wallet Fee -->
          <p v-if="selectedPayment === 'wallet'" class="mt-2 text-red-400 font-bold text-xs md:text-sm font-kanit">
              ค่าธรรมเนียม 20 บาท จะถูกหักจากยอดชำระ
          </p>
        </div>

        <div class="mb-8 w-full flex flex-col items-center px-4">
          <h3 class="text-lg font-bold mb-4 font-kanit">กรุณาเลือกวันและเวลา</h3>
          <input
            id="datetime-picker"
            type="text"
            placeholder="เลือกวันและเวลา"
            class="p-3 rounded-lg shadow-sm text-gray-700 w-full max-w-xs text-center border border-gray-300 focus:outline-none text-sm md:text-base"
          />
        </div>

        <!-- Upload Proof -->
        <div class="w-full max-w-md px-4">
          <h3 class="text-lg font-bold mb-4 text-center font-kanit">อัปโหลดหลักฐานการโอน</h3>
          <!-- Label for Upload -->
          <label
            v-if="!previewImage"
            for="upload-image"
            class="flex items-center justify-center w-full h-20 border-2 border-dashed border-gray-400 rounded-lg text-gray-500 font-bold cursor-pointer hover:border-gray-600 hover:text-gray-700 transition text-sm md:text-base"
          >
            <span class="text-2xl mr-2 font-kanit">+</span> อัปโหลดรูปภาพ
          </label>
          <!-- Hidden Input -->
          <input
            type="file"
            id="upload-image"
            accept="image/*"
            class="hidden"
            @change="handleImageUpload"
            ref="uploadImage"
          />
          <!-- Display Uploaded Image -->
          <div v-if="previewImage" class="mt-4">
            <img
              :src="previewImage"
              alt="Uploaded Preview"
              class="w-full max-h-64 object-cover rounded-lg border-2 border-gray-400"
            />
          </div>
        </div>
        <button 
          @click="confirmPayment"
          :disabled="isLoading"
          class="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded mt-4 w-full max-w-md font-kanit transition disabled:bg-gray-400 disabled:cursor-not-allowed text-sm md:text-base"
        >
          <span v-if="isLoading" class="flex items-center justify-center gap-2">
            <span class="loader"></span> กำลังดำเนินการ...
          </span>
          <span v-else>ยืนยันการชำระเงิน</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import flatpickr from "flatpickr";
import {useToast} from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
import ProfileSummary from "../components/ProfileSummary.vue";
const apiUrl = process.env.VUE_APP_API_URL;
import authService from "@/services/authService";

export default {
  name: "PaymentPage",
  components: { ProfileSummary },
  data() {
    return {
      user: null,
      previewImage: null,
      selectedDateTime: null,
      amount: 0,
      points: 0,
      selectedPayment: "bank",
      isLoading: false,
    };
  },
  methods: {
    resetForm() {
      this.amount = 0;
      this.points = 0;
      this.selectedDateTime = null;
      this.previewImage = null;
      this.$refs.uploadImage.value = null;
    },
    validateForm() {
      const toast = useToast();
      if (!this.amount || this.amount <= 0) {
        toast.error("กรุณากรอกจำนวนเงินที่ถูกต้อง");
        return false;
      }
      if (!this.selectedDateTime) {
        toast.error("กรุณาเลือกวันและเวลา");
        return false;
      }
      if (!this.previewImage) {
        toast.error("กรุณาอัปโหลดหลักฐานการโอน");
        return false;
      }
      return true;
    },
    async confirmPayment() {
      const toast = useToast();
      if (this.isLoading) return;
      if (this.validateForm()) {
        this.isLoading = true;
        try {
          const imageFile = this.$refs.uploadImage.files[0];
          if (!imageFile) {
            toast.error("กรุณาอัปโหลดรูปภาพ");
            this.isLoading = false;
            return;
          }

          const response = await fetch(`${apiUrl}/api/generate-signature`);
          if (!response.ok) throw new Error("Failed to fetch signature");

          const { signature, timestamp } = await response.json();
          const formData = new FormData();
          formData.append("file", imageFile);
          formData.append("upload_preset", "Qubit API");
          formData.append("timestamp", timestamp);
          formData.append("signature", signature);
          formData.append("api_key", "634876621543333");

          const uploadResponse = await fetch("https://api.cloudinary.com/v1_1/df8o1j799/image/upload", {
            method: "POST",
            body: formData,
          });
          if (!uploadResponse.ok) throw new Error("Failed to upload image");

          const uploadResult = await uploadResponse.json();
          const imageUrl = uploadResult.secure_url;
          if (!imageUrl) throw new Error("การอัปโหลดรูปภาพล้มเหลว: ไม่มี secure_url");

          const paymentResponse = await fetch(`${apiUrl}/api/confirm-payment`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              userId: this.user.id,
              amount: this.amount,
              dateTime: this.selectedDateTime,
              imageUrl,
            }),
          });

          if (!paymentResponse.ok) throw new Error("Failed to confirm payment");

          toast.success("ยืนยันการชำระเงินสำเร็จ!");
          this.resetForm();
        } catch (error) {
          toast.error(`เกิดข้อผิดพลาด: ${error.message}`);
          console.error("เกิดข้อผิดพลาด:", error.message);
        } finally {
          this.isLoading = false;
        }
      }
    },
    async getUser() {
      try {
        this.user = await authService.getUser();
      } catch (error) {
        authService.login();
      }
    },
    calculatePoints() {
      if (this.amount > 0) {
        this.points = this.amount;
        if (this.selectedPayment === "wallet") {
          this.points -= 20;
          if (this.points < 0) this.points = 0;
        }
      } else {
        this.points = 0;
      }
    },
    selectPaymentMethod(method) {
      this.selectedPayment = method;
      this.calculatePoints();
    },
    handleImageUpload(event) {
      const file = event.target.files[0];
      const toast = useToast();

      if (file) {
        if (!file.type.startsWith("image/")) {
          toast.error("กรุณาอัปโหลดไฟล์รูปภาพ");
          return;
        }
        if (file.size > 5 * 1024 * 1024) {
          toast.error("ไฟล์มีขนาดใหญ่เกินไป");
          return;
        }
        this.previewImage = URL.createObjectURL(file);
      } else {
        this.previewImage = null;
        console.error("No file selected");
      }
    },
  },
  mounted() {
    this.getUser();
    flatpickr("#datetime-picker", {
      enableTime: true,
      dateFormat: "Y-m-d H:i",
      defaultDate: new Date(),
      onChange: (selectedDates) => {
        this.selectedDateTime = selectedDates[0];
      },
    });
  },
};
</script>

<style scoped>
.payment-content {
  max-width: 1200px;
  width: 100%;
}

/* Loader สไตล์ง่าย ๆ สำหรับแสดงตอน loading */
.loader {
  border: 4px solid rgba(255, 255, 255, 0.2);
  border-top: 4px solid #fff;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;
}

/* Keyframes สำหรับหมุน loader */
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* ปรับตัวอักษรบนมือถือให้ขนาดเล็กลงและมองเห็นง่ายขึ้น */
@media (max-width: 768px) {
  h2.text-4xl { font-size: 2rem; }
  h3.text-3xl { font-size: 1.75rem; }
  p.text-4xl { font-size: 2rem; }
}
</style>
