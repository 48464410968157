<template>
  <nav class="text-white px-6 py-3 flex items-center justify-between">
    <!-- Logo -->
    <div class="flex items-center gap-2">
      <span class="text-lg font-bold">QUBIT DEVELOPER</span>
    </div>

    <!-- Hamburger Button (Mobile) -->
    <button
      @click="toggleMobileMenu"
      class="block md:hidden text-white hover:text-gray-400 focus:outline-none"
    >
      <svg class="h-6 w-6 fill-current" viewBox="0 0 24 24">
        <path
          v-if="!mobileMenuOpen"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M4 5h16v2H4zM4 11h16v2H4zM4 17h16v2H4z"
        />
        <path
          v-else
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M6 18L18 6M6 6l12 12"
        />
      </svg>
    </button>

    <!-- Menu Items -->
    <div class="hidden md:flex items-center gap-6">
      <ul class="flex gap-6 text-sm font-semibold">
        <li><router-link to="/" class="hover:text-gray-400">HOME</router-link></li>
        <li><router-link to="/products" class="hover:text-gray-400">PRODUCTS</router-link></li>
        <li><a href="https://discord.gg/SEHYyA5jtW" class="hover:text-gray-400" target="_blank" rel="noopener noreferrer">DISCORD</a></li>
      </ul>

      <!-- Login/Logout -->
      <div class="flex items-center gap-4">
        <!-- Logged-in State -->
        <div v-if="user" class="relative">
          <button
            @click="toggleDropdown"
            class="flex items-center bg-green-600 text-white px-3 py-1 rounded-full gap-2 hover:bg-green-700"
          >
            <span class="text-lg font-bold">{{ userBalance }}</span>
            <img :src="avatarUrl" alt="Avatar" class="w-8 h-8 rounded-full object-cover" />
          </button>

          <!-- Dropdown Menu -->
          <div
            v-if="isDropdownOpen"
            class="absolute right-0 mt-2 w-40 bg-darkGray text-white shadow-lg rounded-lg py-2"
          >
            <ul>
              <li class="px-4 py-2 hover:bg-gray-800 cursor-pointer" @click="goToDashboard">
                Dashboard
              </li>
              <li class="px-4 py-2 hover:bg-gray-800 cursor-pointer" @click="goToPayment">
                Payment
              </li>
              <li class="px-4 py-2 hover:bg-gray-800 cursor-pointer" @click="goToStatement">
                Statement
              </li>
              <li class="px-4 py-2 hover:bg-gray-800 cursor-pointer" @click="logout">
                Logout
              </li>
            </ul>
          </div>
        </div>

        <!-- Logged-out State -->
        <button
          v-else
          @click="login"
          class="flex items-center bg-blue-600 hover:bg-blue-700 text-white text-sm font-semibold py-2 px-4 rounded"
        >
          <img :src="require('@/assets/discord-icon.png')" alt="Discord" class="h-5 mr-2">
          LOGIN
        </button>
      </div>
    </div>
    <!-- Mobile Menu -->
  <div
    v-if="mobileMenuOpen"
    class="bg-darkGray text-white p-4 md:hidden animate-slideDown"
  >
    <ul class="flex flex-col gap-4 text-sm font-semibold mb-4">
      <li><router-link @click="closeMobileMenu" to="/" class="hover:text-gray-400 block">HOME</router-link></li>
      <li><router-link @click="closeMobileMenu" to="/products" class="hover:text-gray-400 block">PRODUCTS</router-link></li>
      <li><a @click="closeMobileMenu" href="https://discord.gg/SEHYyA5jtW" class="hover:text-gray-400 block" target="_blank" rel="noopener noreferrer">DISCORD</a></li>
    </ul>

    <!-- Login/Logout Mobile -->
    <div class="flex flex-col gap-4">
      <div v-if="user" class="relative">
        <div class="flex items-center justify-between bg-green-600 text-white px-3 py-2 rounded-full gap-2 hover:bg-green-700">
          <div class="flex items-center gap-2">
            <span class="text-lg font-bold">{{ userBalance }}</span>
            <img :src="avatarUrl" alt="Avatar" class="w-8 h-8 rounded-full object-cover" />
          </div>
          <button @click="toggleDropdown" class="focus:outline-none">
            <svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
              <path
                v-if="!isDropdownOpen"
                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1
                 1 0 011.414 1.414l-4 4a1 1 0 
                 01-1.414 0l-4-4a1 1 0 010-1.414z"
              />
              <path
                v-else
                d="M5.293 12.707a1 1 0 
                 001.414 0L10 9.414l3.293 
                 3.293a1 1 0 001.414-1.414l-4-4a1 
                 1 0 00-1.414 0l-4 4a1 1 0 
                 000 1.414z"
              />
            </svg>
          </button>
        </div>

        <!-- Dropdown Menu Mobile -->
        <div
          v-if="isDropdownOpen"
          class="mt-2 w-full bg-darkGray text-white shadow-lg rounded-lg py-2 z-50"
        >
          <ul class="text-center">
            <li class="px-4 py-2 hover:bg-gray-800 cursor-pointer" @click="goToDashboard">Dashboard</li>
            <li class="px-4 py-2 hover:bg-gray-800 cursor-pointer" @click="goToPayment">Payment</li>
            <li class="px-4 py-2 hover:bg-gray-800 cursor-pointer" @click="goToStatement">Statement</li>
            <li class="px-4 py-2 hover:bg-gray-800 cursor-pointer" @click="logout">Logout</li>
          </ul>
        </div>
      </div>
      <button
        v-else
        @click="login"
        class="flex items-center justify-center bg-blue-600 hover:bg-blue-700 text-white text-sm font-semibold py-2 px-4 rounded"
      >
        <img :src="require('@/assets/discord-icon.png')" alt="Discord" class="h-5 mr-2">
        LOGIN
      </button>
    </div>
  </div>
</nav>

</template>

<script>
import authService from "@/services/authService";

export default {
  name: "NavBar",
  data() {
    return {
      user: null,
      userBalance: 0,
      isDropdownOpen: false,
      mobileMenuOpen: false,
    };
  },
  computed: {
    avatarUrl() {
      if (this.user && this.user.id && this.user.avatar) {
        return `https://cdn.discordapp.com/avatars/${this.user.id}/${this.user.avatar}.png`;
      }
      return null;
    },
  },
  methods: {
    async toggleDropdown() {
      if (!this.user) {
        this.login();
        return;
      }
      this.isDropdownOpen = !this.isDropdownOpen;
    },
    async login() {
      authService.login();
    },
    async logout() {
      this.isDropdownOpen = false;
      try {
        await authService.logout();
        this.user = null;
        window.location.reload();
      } catch (error) {
        console.error("Logout failed:", error);
      }
    },
    async getUser() {
      try {
        this.user = await authService.getUser();
        const userData = await authService.getUserData();
        this.userBalance = userData.point || 0;
        this.$store.dispatch("updateUserBalance", userData.point);
      } catch (error) {
        console.error(error.message);
        this.user = null;
        this.userBalance = 0;
      }
    },
    async goToDashboard() {
      this.isDropdownOpen = false;
      this.mobileMenuOpen = false;
      this.$router.push('/dashboard');
    },
    async goToPayment() {
      this.isDropdownOpen = false;
      this.mobileMenuOpen = false;
      this.$router.push('/payment');
    },
    async goToStatement() {
      this.isDropdownOpen = false;
      this.mobileMenuOpen = false;
      this.$router.push('/statement');
    },
    toggleMobileMenu() {
      this.mobileMenuOpen = !this.mobileMenuOpen;
      this.isDropdownOpen = false;
    },
    closeMobileMenu() {
      this.mobileMenuOpen = false;
      this.isDropdownOpen = false;
    },
    setupWebSocket() {
      const userId = this.user ? this.user.id : null;
      if (!userId) return;

      this.socket = new WebSocket(`${process.env.VUE_APP_API_WSS}?user_id=${userId}`);
      this.socket.onmessage = (event) => {
        const message = JSON.parse(event.data);
        if (message.type === "balanceUpdated") {
          this.userBalance = message.newBalance;
          this.$store.dispatch("updateUserBalance", message.newBalance);
        }
      };
      this.socket.onclose = () => {
        setTimeout(() => this.setupWebSocket(), 5000);
      };
    },
  },
  mounted() {
    this.getUser().then(() => {
      this.setupWebSocket();
    });
  },
};
</script>

<style scoped>
.bar-content {
  max-width: 1200px; /* จำกัดความกว้าง */
  width: 100%; /* ยืดความกว้างเต็มที่ */
}
.bg-darkGray {
  background-color: #1f1f1f;
}

/* เพิ่ม animation เบื้องต้นสำหรับ dropdown mobile menu */
@keyframes slideDown {
  0% {
    opacity: 0;
    transform: translateY(-10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.animate-slideDown {
  animation: slideDown 0.2s ease-in-out forwards;
}
</style>