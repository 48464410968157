<template>
    <!-- FeatureSection -->
    <FeatureSection />

    <div class="recommended-container">
        <div class="recommended-content">
        <RecommendedProducts />
        </div>
    </div>

    <div class="statssection-container">
        <StatsSection />
    </div>
</template>

<script>
import FeatureSection from '../components/FeatureSection.vue';
import RecommendedProducts from '../components/RecommendedProducts.vue';
import StatsSection from '../components/StatsSection.vue';

export default {
  name: "HomePage",
  components: {
    FeatureSection,
    RecommendedProducts,
    StatsSection,
  },
};
</script>

<style scoped>
/* Recommended Container */
.recommended-container {
  width: 100%; /* ทำให้พื้นหลังยาวเต็มหน้าจอ */
  background: linear-gradient(to bottom, #727272 0%, #474747 100%);
  padding: 20px 0; /* Padding ด้านบนและล่าง */
  display: flex;
  justify-content: center; /* จัดเนื้อหาให้อยู่กึ่งกลางแนวนอน */
  box-sizing: border-box;
}

.recommended-content {
  max-width: 1000px; /* จำกัดความกว้าง */
  width: 100%; /* ยืดความกว้างเต็มที่ */
}
</style>

