<template>
  <button
    class="bg-custom-gradient rounded-lg shadow-md overflow-hidden p-2 transition transform hover:scale-105"
    @click="goToProductDetail"
  >
    <div class="bg-gray-800 rounded-lg overflow-hidden">
      <img
        :src="image"
        alt="Product Image"
        class="w-full h-48 object-cover"
        />
    </div>
    <div class="p-4 text-center">
      <h3 class="text-white text-lg font-bold uppercase">{{ name }}</h3>
      <p class="text-sm text-gray-400 font-kanit dynamic-size">{{ description }}</p>
      <p class="text-green-400 text-lg font-bold mt-2">{{ price }} POINT</p>
    </div>
  </button>
</template>

<script>
export default {
  name: "ProductCard",
  props: {
    name: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    price: {
      type: [String, Number],
      required: true,
    },
    image: {
      type: String,
      required: true,
    },
  },
  methods: {
    goToProductDetail() {
      const encodedName = btoa(this.name); // เข้ารหัสชื่อสินค้าเป็น Base64
      this.$router.push(`/products/${encodedName}`); // เปลี่ยนเส้นทางไปที่ /products/name
    },
  },
};
</script>

<style scoped>
.bg-custom-gradient {
  background: linear-gradient(to bottom, #1d1d1d 0%, #282828 100%);
}

.dynamic-size {
  font-size: clamp(0.5rem, 0.7vw, 1rem); /* ลดขนาดตามพื้นที่ */
  line-height: 1.2; /* ควบคุมความสูงของบรรทัด */
  overflow: hidden; /* ป้องกันข้อความเกิน */
  text-overflow: ellipsis; /* ตัดข้อความด้วย ... */
  display: -webkit-box; /* รองรับ multi-line ellipsis */
  -webkit-line-clamp: 1; /* จำกัดจำนวนบรรทัด */
  -webkit-box-orient: vertical;
}

</style>
