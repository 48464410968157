const API_URL_BACKEND = process.env.VUE_APP_API_URL;

const authService = {
  memoryCache: new Map(),

  clearCache() {
    this.memoryCache.clear();
    localStorage.removeItem("user");
    sessionStorage.removeItem("userData");
  },

  async login() {
    window.location.href = `${API_URL_BACKEND}/api/login`;
  },

  async logout() {
    try {
      const response = await fetch(`${API_URL_BACKEND}/api/logout`, {
        method: "POST",
        credentials: "include",
      });

      if (!response.ok) {
        throw new Error("Logout failed");
      }

      this.clearCache(); // Clear all cached data
      return await response.text();
    } catch (error) {
      console.error("Logout failed:", error);
      throw error;
    }
  },

  async getUser() {
    const cacheKey = "user";

    if (this.memoryCache.has(cacheKey)) {
      return this.memoryCache.get(cacheKey);
    }

    const cachedUser = localStorage.getItem(cacheKey);
    if (cachedUser) {
      const user = JSON.parse(cachedUser);
      this.memoryCache.set(cacheKey, user);
      return user;
    }

    try {
      const response = await fetch(`${API_URL_BACKEND}/api/user`, {
        credentials: "include",
      });

      if (!response.ok) {
        throw new Error("Failed to fetch user");
      }

      const user = await response.json();

      this.memoryCache.set(cacheKey, user);
      localStorage.setItem(cacheKey, JSON.stringify(user));
      return user;
    } catch (error) {
      console.error("Failed to fetch user:", error);
      throw error;
    }
  },

  async getUserData() {
    const cacheKey = "userData";

    if (this.memoryCache.has(cacheKey)) {
      return this.memoryCache.get(cacheKey);
    }

    const cachedUserData = sessionStorage.getItem(cacheKey);
    if (cachedUserData) {
      const userData = JSON.parse(cachedUserData);
      this.memoryCache.set(cacheKey, userData);
      return userData;
    }

    try {
      const response = await fetch(`${API_URL_BACKEND}/api/userdata`, {
        credentials: "include",
      });

      if (!response.ok) {
        throw new Error("Failed to fetch user data");
      }

      const userData = await response.json();
      
      this.memoryCache.set(cacheKey, userData);
      sessionStorage.setItem(cacheKey, JSON.stringify(userData));
      return userData;
    } catch (error) {
      console.error("Failed to fetch user data:", error);
      throw error;
    }
  },
};

export default authService;
